import React, { Fragment, useEffect, useState } from 'react'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Head,
  Menubar,
  AnimatedLogo,
  ValuesTabs,
  Testimonials,
  Slider,
  Modal,
} from '@components'
//import api from '@api/blog'
import { Link } from 'gatsby'
import * as st from '@assets/styl/home.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'

import bannerReferralCampaign from '@assets/img/banner-campanha-de-indicacao.png'

/*function getThumbnail(post: any) {
  return post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0] && post._embedded["wp:featuredmedia"][0].source_url
}*/

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      allNumbers {
        nodes {
          name
          number
          title
        }
      }
      allReleases {
        nodes {
          facades
          name
          neighborhood
          city
          room
          tag
          url
          feature
        }
      }
      allPosts {
        nodes {
          title
          site
          url
        }
      }
    }
  `)
  const numbers = data.allNumbers.nodes
  const releases = data.allReleases.nodes
  const posts = data.allPosts.nodes
  const [modalOpen, setModalOpen] = useState(false)

  /*const [posts, setPosts] = useState([])
  useEffect(() => {
    api('get', 'posts?_embed').then((posts) => {
      !posts.errors && setPosts(posts)
    })
  }, [])*/

  const { t } = useLanguage()

  return (
    <>
      <Head pathname="/" />

      <section className={st.hero} id="hero">
        <Menubar pathname="/" position="absolute" />
        <button
          className={st.videoCta}
          onClick={() => setModalOpen(true)}
        ></button>
        <AnimatedLogo />
      </section>

      <section className={st.welcome}>
        <div>
          <h1>{t('Home.title')}</h1>
          <h2>
            {t('Home.paragraph.1')}
            <br />
            {t('Home.paragraph.2')}
            <br />
            {t('Home.paragraph.3')}
          </h2>
          <Link to={translatePath('/jeito-dimas/')} className="btn">
            {t('Home.button')}
          </Link>
        </div>
      </section>

      <ValuesTabs />

      <section className={st.practice}>
        <div>
          <h3>{t('Home.dimasWay')}</h3>
          <ul>
            <li>
              <span>{t('Home.uniqueSolutions')}</span>
            </li>
            <li>
              <span>{t('Home.improveTheCity')}</span>
            </li>
            <li>
              <span>{t('Home.dDimas')}</span>
            </li>
          </ul>
        </div>
      </section>

      <section className={st.releases}>
        <div>
          <h3>{t('Home.releases')}</h3>
          <ul>
            {releases
              .filter((r) => r.feature)
              .map((release, key) => (
                <LazyImageFull key={key} src={release.facades[0]}>
                  {({ imageState, ref }) => (
                    <li
                      ref={ref}
                      key={key}
                      style={{
                        backgroundImage: `url(${
                          imageState === ImageState.LoadSuccess
                            ? release.facades[0]
                            : ''
                        })`,
                      }}
                    >
                      <a href={release.url} target="_blank">
                        <div>
                          <h4>{release.name}</h4>
                          <p>
                            {release.neighborhood} / {release.city}
                            <br />
                            {release.room}
                          </p>
                          {Boolean(release.tag) && (
                            <div className={st.tag}>{release.tag}</div>
                          )}
                        </div>
                      </a>
                    </li>
                  )}
                </LazyImageFull>
              ))}
          </ul>
          <ul className={st.numbers}>
            {numbers.map((number, key) => (
              <Fragment key={key}>
                {key > 0 && <li></li>}
                <li>
                  <b>{number.number}</b>
                  {number.title}
                </li>
              </Fragment>
            ))}
          </ul>
          <Link
            to={translatePath('/empreendimentos/entregues/')}
            className="btn"
          >
            {t('Home.discoverOurPortfolio')}
          </Link>
        </div>
      </section>

      {/* Banner Campanha de indicação */}
      <div className={st.bannerReferralCampaign}>
        <a href={translatePath('/campanha-de-indicacao/')}>
          <LazyImageFull src={bannerReferralCampaign}>
            {({ imageState, ref }) => (
              <div
                ref={ref}
                style={{
                  backgroundImage: `url(${
                    imageState === ImageState.LoadSuccess
                      ? bannerReferralCampaign
                      : ''
                  })`,
                }}
              ></div>
            )}
          </LazyImageFull>
        </a>
      </div>

      <Testimonials />

      {/*{Boolean(posts && posts.length) && <section className={st.blog}><div>
      <h3>Blog</h3>
      <Slider classes={{ root: st.slidesWrapper, arrow: st.arrow }} dots slidesToShow={3}>
        {posts.map((post, key) =>
          <div key={key} className={st.slide} itemScope itemType="http://schema.org/BlogPosting">
            <LazyImageFull src={getThumbnail(post)}>{({ imageState, ref }) =>
              <a ref={ref} href={post.link} target="_blank" style={{ backgroundImage: `url(${imageState === ImageState.LoadSuccess ? getThumbnail(post) : ''})` }}>
                <meta itemProp="image" content={getThumbnail(post)} />
              </a>
            }</LazyImageFull>
            <span><a href={post.link} target="_blank" itemProp="datePublished">{new Date(post.date).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' })}</a></span>
            <h4><a href={post.link} target="_blank" itemProp="name">{post.title.rendered}</a></h4>
          </div>)}
      </Slider>
    </div></section>}*/}

      {Boolean(posts && posts.length) && (
        <section className={st.blog}>
          <div>
            <h3>{t('Home.intheMedia')}</h3>
            <Slider
              classes={{ root: st.slidesWrapper, arrow: st.arrow }}
              dots
              slidesToShow={3}
              responsiveSize={650}
            >
              {posts.map((post, key) => (
                <a
                  href={post.url}
                  target="_blank"
                  key={key}
                  className={st.slide}
                  itemScope
                  itemType="http://schema.org/BlogPosting"
                >
                  <meta itemProp="url" content={post.url} />
                  <span itemProp="publisher">{post.site}</span>
                  <h4 itemProp="name">{post.title}</h4>
                </a>
              ))}
            </Slider>
          </div>
        </section>
      )}

      <Modal
        open={modalOpen}
        close={() => setModalOpen(false)}
        className={st.videoModal}
        clientOnly
      >
        <iframe
          src="https://www.youtube.com/embed/fCfVQdv_f1M"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  )
}

export default Home
