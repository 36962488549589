// extracted by mini-css-extract-plugin
export var hero = "home-module--hero--74SI2";
export var videoCta = "home-module--video-cta--CzxTe";
export var rotate = "home-module--rotate--ofIw4";
export var welcome = "home-module--welcome--2aI-f";
export var releases = "home-module--releases--FheaD";
export var blog = "home-module--blog--5AUEA";
export var practice = "home-module--practice--exVRc";
export var numbers = "home-module--numbers--QEUzf";
export var tag = "home-module--tag--PqADG";
export var bannerReferralCampaign = "home-module--bannerReferralCampaign--Ljj4A";
export var slide = "home-module--slide--ozb4s";
export var videoModal = "home-module--video-modal--yRUWS";
export var slidesWrapper = "home-module--slidesWrapper--RSmoF";
export var arrow = "home-module--arrow--qDNIl";